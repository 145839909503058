/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import HeroBannerItem from './HeroBannerItem';
import { HeroBannerProps } from './models';
import './styles.scss';

export const HeroBanner: FC<HeroBannerProps> = ({ data, className }) => {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const carousel = data.map(({ properties }) => properties);

  return (
    <div data-testid="hero-banner" className={className}>
      <Slider className="hero-banner-slider" {...settings}>
        {carousel.map((properties, index) => (
          <HeroBannerItem key={`hero-banner-${index}`} {...properties} />
        ))}
      </Slider>
    </div>
  );
};

export const query = graphql`
  fragment HeroBannerFragment on HeroBanner {
    properties {
      url {
        name
        url
      }
      imageAlt
      image {
        ...ImageFragment
      }
    }
  }
`;

export default HeroBanner;
