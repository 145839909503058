import React, { FC } from 'react';
import Image from 'common/Image';
import { Link } from 'gatsby';
import { RelatedArticleItemProps } from './models';
import './RelatedArticleItem.scss';

const RelatedArticleItem: FC<RelatedArticleItemProps> = ({ title, image, link }) => (
  <div data-testid="related-article-item" className="related-article-item">
    <Image imageData={image} alt={title} className="related-article-item__image" />
    <div className="related-article-item__link-layer">
      <Link className="related-article-item__link" to={link?.[0].url}>
        <span className="related-article-item__link-title">{title}</span>
      </Link>
    </div>
  </div>
);

export default RelatedArticleItem;
