import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import SectionName from 'common/SectionName';
import SectionTitle from 'common/SectionTitle';
import Container from 'react-bootstrap/Container';
import RelatedArticlesColumn from './RelatedArticlesColumn';
import { RelatedArticlesPropertiesProps } from './models';
import './RelatedArticles.scss';

const RelatedArticles: FC<RelatedArticlesPropertiesProps> = ({
  relatedArticlesGrid,
  sectionName,
  title,
  buttonLink,
  className,
}) => (
  <section data-testid="related-articles" className={classNames('related-articles', className)}>
    <Container>
      <Row>
        <Col lg={12}>
          <div className="related-articles__top">
            <SectionName text={sectionName} />
            <SectionTitle text={title} />
          </div>
        </Col>
        <Col>
          <div className="related-articles__list">
            {relatedArticlesGrid.map(({ properties }, index) => {
              const key = `${index}-${properties.columnTitle.replace(/\s/g, '-')}`;

              return <RelatedArticlesColumn key={key} {...{ ...properties, index: index + 1 }} />;
            })}
          </div>
        </Col>
      </Row>
      {buttonLink ? (
        <Row>
          <Col>
            <div className="related-articles__wrapper">
              <Link className="related-articles__link" to={buttonLink[0].url}>
                {buttonLink[0].name}
              </Link>
            </div>
          </Col>
        </Row>
      ) : null}
    </Container>
  </section>
);

export const query = graphql`
  fragment RelatedArticlesFragment on RelatedArticlesSectionType {
    properties {
      sectionName
      title
      buttonLink {
        name
        url
      }
      relatedArticlesGrid {
        properties {
          columnTitle
          relatedArticlesList {
            structure
            properties {
              title
              link {
                url
                name
              }
              image {
                ...ImageFragment
              }
            }
          }
        }
      }
    }
  }
`;
export default RelatedArticles;
