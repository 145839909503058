import React, { FC } from 'react';
import { Link } from 'gatsby';
import Image from 'common/Image';
import { HeroBannerItemProps } from './models';
import './styles.scss';

export const HeroBannerItem: FC<HeroBannerItemProps> = ({ image, url: [url], imageAlt }) => (
  <div data-testid="hero-banner-item" className="hero-banner">
    {url ? (
      <Link to={url.url}>
        <Image imageData={image} alt={imageAlt} className="hero-banner__image" />
      </Link>
    ) : (
      <Image imageData={image} alt={imageAlt} className="hero-banner__image" />
    )}
  </div>
);

export default HeroBannerItem;
