import React, { FC } from 'react';

import RelatedArticleItem from '../RelatedArticleItem';
import { RelatedArticlesColumnProps } from './models';
import { getColumnNumber } from './utils';
import './RelatedArticlesColumn.scss';

const RelatedArticlesColumn: FC<RelatedArticlesColumnProps> = ({
  index,
  columnTitle,
  relatedArticlesList,
}) => {
  const columnNumber = getColumnNumber(index);

  return (
    <div data-testid="related-article-column" className="related-articles-column">
      <span className="related-articles-column__index">{columnNumber}</span>
      <div className="related-articles-column__title">{columnTitle}</div>
      {relatedArticlesList.map(({ properties }) => {
        const key = `${index}-${properties.title.replace(/\s/g, '-')}`;

        return <RelatedArticleItem key={key} {...properties} />;
      })}
    </div>
  );
};

export default RelatedArticlesColumn;
