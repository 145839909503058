import React, { FC } from 'react';
import { graphql } from 'gatsby';
import HeroBanner from 'components/HeroBanner';

import 'styles/main.scss';
import './HomePage.scss';

import createBreadcrumbs from 'utils/createBreadcrumbs';
import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import RelatedProducts from 'components/RelatedProducts';
import RelatedArticles from 'components/RelatedArticles/RelatedArticles';
import FaqAccordion from 'components/Faq/FaqAccordion';
import { HomePageProps } from './models';

const HomePage: FC<HomePageProps> = ({
  data: {
    homePage: {
      relatedProductsSection: [{ properties: relatedProductsData }],
      relatedArticlesSection: [{ properties: relatedArticlesData }],
      faqSection: [{ properties: faqData }],
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoNoIndex,
      heroBannerSection,
    },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  return (
    <Layout
      className="home-page"
      headerTransparent
      breadcrumbs={breadcrumbs}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoNoIndex }}
    >
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <HeroBanner data={heroBannerSection} className="home-page__hero-banner" />
      <RelatedProducts {...relatedProductsData} />
      <RelatedArticles {...relatedArticlesData} />
      <FaqAccordion {...faqData} />
    </Layout>
  );
};

export const query = graphql`
  query {
    homePage {
      ...SeoFragment
      relatedProductsSection {
        properties {
          sectionName
          title
          relatedProductList {
            properties {
              ...ProductBoxFragment
            }
          }
        }
      }
      heroBannerSection {
        ...HeroBannerFragment
      }
      relatedArticlesSection {
        ...RelatedArticlesFragment
      }
      faqSection {
        ...FaqAccordionFragment
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default HomePage;
